import { render, staticRenderFns } from "./HeaderOptions.vue?vue&type=template&id=34b24b32&scoped=true"
import script from "./HeaderOptions.vue?vue&type=script&lang=js"
export * from "./HeaderOptions.vue?vue&type=script&lang=js"
import style0 from "./HeaderOptions.vue?vue&type=style&index=0&id=34b24b32&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34b24b32",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CLink: require('@chakra-ui/vue').CLink, CBox: require('@chakra-ui/vue').CBox, CAvatarBadge: require('@chakra-ui/vue').CAvatarBadge, CAvatar: require('@chakra-ui/vue').CAvatar, CPopoverTrigger: require('@chakra-ui/vue').CPopoverTrigger, CText: require('@chakra-ui/vue').CText, CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList, CPopoverBody: require('@chakra-ui/vue').CPopoverBody, CPopoverContent: require('@chakra-ui/vue').CPopoverContent, CPopover: require('@chakra-ui/vue').CPopover})
